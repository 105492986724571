/* eslint-disable no-undef */
import React from "react";
import logo from "./images/ic_app_icon_red.png";
import checked from "./images/checked.png";
import cancel from "./images/cancel.png";
import info from "./images/info.png";
import roundClock from "./images/round-clock.png";

import "./App.css";

function App() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const orderId = params.get("order_id");
  const token = params.get("token");
  const status = params.get("status");

  let logoStatus = "";
  let titleStatus = "";
  let descriptionStatus = "";

  switch (status) {
    case "pending":
      logoStatus = roundClock;
      titleStatus = "Pago Pendiente de Pago";
      descriptionStatus = "Su pago está pendiente de pago.";
      break;
    case "failure":
      logoStatus = cancel;
      titleStatus = "Pago Rechazado";
      descriptionStatus = "Hubo un error al procesar el pago";
      break;
    case "review":
      logoStatus = info;
      titleStatus = "Pago en Revisión";
      descriptionStatus = "Su pago está en proceso de revisión";
      break;
    case "success":
      logoStatus = checked;
      titleStatus = "Pago Exitoso";
      descriptionStatus = "Su Pago fue procesado Exitosamente";
      break;
    default:
      logoStatus = info;
      titleStatus = "Sin Respuesta";
      descriptionStatus = "La transacción no retorno un estado";
      break;
  }

  process.nextTick(function () {
    console.log("Click 4");
    const dataSend = JSON.stringify({
      status: status,
      order_id: orderId,
      token: token,
    });
    console.log(dataSend);
    Print.postMessage(dataSend);
  });

  return (
    <div className="App">
      <div className="App-header">
        <img src={logo} alt="logo" style={{ width: "30%" }} />
        <br />
        <br />
        <img
          className="image-status"
          src={logoStatus}
          alt="icon"
          style={{ width: "10%" }}
        />
        <p>
          <b>{titleStatus}</b>
        </p>
        <p className="status-message">{descriptionStatus}</p>
        <p className="status-message">Orden #{orderId}</p>
        <br />
      </div>
    </div>
  );
}

export default App;
